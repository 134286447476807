:root{
  --amplify-primary-color: #8a8aa3;
  --amplify-primary-tint: #60607a; 
  --amplify-primary-shade: #60607a;
  --amplify-light-grey: #8a8aa3;
}
body {
  background-color: #efefef;   /* e1e1e1 #efefef  f1f1f1 */
    /* background: -webkit-linear-gradient(#efefef, #e1e1e1); */ /* For Safari 5.1 to 6.0 */    /* ccd8dd */
    /* background: -o-linear-gradient(#efefef, #e1e1e1); */ /* For Opera 11.1 to 12.0 */
    /* background: -moz-linear-gradient(#efefef, #e1e1e1); */ /* For Firefox 3.6 to 15 */
    /* background: linear-gradient(#efefef, #e1e1e1); */ /* Standard syntax */

  color: #333;
  font-family: 'Amazon Ember', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 80vh;
}

/* login page wrappers */
.amp-auth-infomessage{
  float: right;
  padding-top: 12px;
  padding-bottom: 5px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #efefef;
  text-align: center;
}
@media  (max-width: 765px) {   /*  (max-width: 599px), (max-height: 700px) */
  .amp-auth-infomessage {
    width: 100%;
  }
}
.amp-auth-infolink, .amp-auth-infolink:visited, .amp-auth-infolink:active, .amp-auth-infolink:focus{
  color: #483D8B;  /*  #007bff   3f3f52 */
  margin-left: 10px;
  /* text-decoration: underline; */
  border: none;
  outline: none;  
  cursor: pointer;
}
.amp-auth-infolink:hover{
  color: #747494;
}
.amp-auth-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 97.5vh;
  padding-top: 3px;
}
.amp-auth-wrapper:before {
  content: "";
  background-image: url(/images/highway-night.jpg); 
  background-size: cover;
  background-repeat: no-repeat;

  display: block;
    position: absolute;
    left: 0;
    top: 60px;
    width: 100%;
    height: 84.5vh;
    opacity: 0.55;
}
.amp-auth-portal-title{
  display: inline-flex;
  position: absolute;
  top: 15px;
  padding-top: 0px;
  padding-left: 6px;
  padding-bottom: 0px;
  font-weight: 600;
  font-size: 1.2rem;
  /* line-height: 1.225rem; */
  color: #60607a;
  font-family: sans-serif, "Helvetica Neue", "Lucida Grande", Arial;
  font-stretch: expanded !important;
}
.amp-auth-body {
  flex: 1 0 auto !important;
  padding: var(--space) var(--space) 0 !important;
  width: 100%  !important;
    /* margin-top: var(--space)  !important; */
    /* margin-bottom: 8px; */
    z-index: 10 !important;
    margin-bottom: 5px  !important;
}
/* over ride some new css from amplify that hosed the sign-in form */
/*  10/26/2020 - fjp -  "@aws-amplify/api": "^3.2.7", "@aws-amplify/pubsub": "^3.2.5", "@aws-amplify/ui-react": "^0.2.24", "aws-amplify": "^3.3.4", "aws-amplify-react": "^4.2.8",   */
amplify-sign-in, amplify-confirm-sign-in, amplify-confirm-sign-up, 
amplify-forgot-password, amplify-require-new-password, 
amplify-sign-up, amplify-verify-contact,
.amp-auth-signin-override, .amp-auth-body {
  --container-height: none !important;
  /* --container-display: none !important; */
  /* --container-justify: none !important; */
  /* --container-align: none !important; */
}

.amp-auth-body:after {
  /* this becomes the sticky bottom footer */
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}
.amp-auth-footer{
  font-size: 0.825rem;
  line-height: 1.1rem;
  text-align: center;
  color: #333;
  z-index: 10 !important;
  padding-bottom: 0;
  margin-bottom: 0;
}
.version-footer{
  font-size: 0.8rem;
  font-stretch: semi-condensed;
}
.dime-logo-shim{
  /* padding: 3px 0px 3px 3px !important; */
  z-index: 10 !important;
}
@media  (max-height: 700px) {   /*  (max-width: 599px), (max-height: 700px) */
  .amp-auth-footer {
    display: none;
  }
}


/*  content layout */
.alert-hdr-row {
  background-color: #efefef; 
  padding-left: 8px;
  padding-right: 8px;
}
.alert-hdr-msg{
  font-size: 0.95rem;
  /* line-height: 1.2rem; */
  text-align: center;
  padding-top: 5px ;
  padding-bottom: 5px ;
  margin-top: 15px;
  margin-bottom: 5px;
}
.alert-hdr-link {
  /* make <button> look like link */
  display: inline;
  border: 0 !important;
  outline: 0 !important;
  background-color: rgba(0,0,0,0.0) !important;
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
  color: inherit;
}
.alert-hdr-link:hover {
  color: #678eb8 ;  
      /* #678eb8 is lighter version of "primary" bootstrap-alert text color #004085  */
      /* #002464 is darker version of "primary" bootstrap-alert text color #004085  */
}
.alert-hdr-msg-sm{
  font-size: 0.8rem;
}
.menu-portal-title{
  display: inline-flex;
  padding-top: 13px;
  padding-left: 6px;
  font-weight: 600;
  font-size: 1.2rem;
  color: #60607a;
  font-family: sans-serif, "Helvetica Neue", "Lucida Grande", Arial;
  font-stretch: expanded !important;
}
.hdr-dime{
  /* border-bottom: 1px solid #8a8aa3; */
  /* max-height: 64px; */
  /* background-color: #bbbbd4; */
  background-color: #fff;
  /* height: 58px;
  max-height: 58px; */
  /* width: 100%; */
  /* border: 1px solid red;
  margin-top: 1px; */
  /* margin: 0; */
}
.hdr-left{
  padding: 15px 5px 2px 30px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #60607a;
}
.hdr-center{
  /* padding-top: 5px;
  padding-bottom: 0px; */
  height: 58px;
  max-height: 58px;
  /* margin-top: 5px; */
}
.hdr-right{
  /* padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 3px; */
  height: 58px;
  max-height: 58px;
  /* clear: both;
  float: right; */
  text-align: right;
  /* margin-top: 10px; */
}
.hdr-icon, .hdr-icon:focus{
  height: 36px;
  max-height: 36px;
  width: 36px;
  max-width: 36px;
  padding: 0px 0px 0px 0px;
  text-align: center;
  background-color: #8a8aa3;
  border: none !important;
  outline: none !important;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 1.2rem;
}
.hdr-icon:hover{
  background-color: #3f3f52;
}
.hdr-icon:hover .hrd-icon-swing, .hrd-icon-swing:hover{
  animation: swing ease-in-out 0.5s 1 alternate !important;
}
.hdr-icon:disabled{
  background-color: #8a8aa3;
  
}
.hdr-icon:hover:disabled .hrd-icon-swing, .hrd-icon-swing:hover:disabled{
  animation: none !important;
}

.hdr-icon-rtrlink{
  color: #ffffff;  /* same as .btn-primary */
}
.dime-page{
  height: 100vh;
  max-width: "100%";
}
.dime-menu-parent{
  /* width: 270px; */
  max-width: 270px;
}
.dime-page-col{
  padding: 0;
}
.dime-content{
  /* padding-left: 30px;
  padding-top: 10px; */
  padding: 10px 10px 5px 25px;
}
.dime-content-desc{
  margin-top: -5px;
  padding: 0px 0px 8px 10px;
}
.dime-content-desc svg{
  color: #444;
  /* padding-right: 5px; */
  font-size: 0.9rem;
  margin-right: 7px;
}
.dime-loading{
  margin: auto;
  width: 50%;
  text-align: center;
  padding-top: 30vh;
  color: #8a8aa3;
  font-size: 2rem;
  font-weight: 600;
}
.dime-404{
  font-size: 1.2rem;
  margin-top: 20px;
  /* line-height: 1.2rem;
  text-align: center;
  padding-top: 2px ;
  padding-bottom: 2px ; */
  /* margin-top: 7px; */
  /* margin-top: 10px;
  margin-bottom: 10px; */
}
.dime-dropdown, .dime-dropdown:focus, .dime-dropdown.focus{
  border: none !important;
  background-color: #747494 !important;
  color: #ffffff;
}
.dime-dropdown-lt, .dime-dropdown-lt:focus, .dime-dropdown-lt.focus{
  border: none !important;
  background-color: #8a8aa3 !important;
  color: #ffffff;
}
.dime-dropdown-outline, .dime-dropdown-outline:focus, .dime-dropdown-outline.focus {
  background-color: #efefef !important;
  color: #3f3f52;
}
.dime-dropdown-outline.btn, .dime-dropdown-outline.btn:focus, .dime-dropdown-outline.btn.focus{
  border: 2px solid #747494 !important;
  color: #3f3f52;
}
.dime-dropdown-outline.btn-primary.dropdown-toggle{
  color: #3f3f52;
}

.dime-dropdown, .dime-dropdown:focus, .dime-dropdown.focus, 
.dime-dropdown-outline, .dime-dropdown-outline:focus, .dime-dropdown-outline.focus {
  /* background-color: #747494 !important; */
  /* border: none !important; */
  outline: none !important;
  /* border-color: rgba(0,0,0,0.0) !important; */
  font-size: 1.025rem;
}
.dime-dropdown-lt, .dime-dropdown-lt:focus, .dime-dropdown-lt.focus {
  outline: none !important;
  font-size: 0.85rem;
}
.dime-dropdown::after, .dime-dropdown-outline::after, .dime-dropdown-lt::after {
  margin-left: 10px;  /* scooch the dropdown arrow over a little bit */
}
.dime-dropdown:hover, .dime-dropdown.hover, 
.dime-dropdown-outline:hover, .dime-dropdown-outline.hover {
  background-color: #3f3f52;
}
.dime-dropdown-lt:hover, .dime-dropdown-lt.hover {
  background-color: #60607a;
}
.dime-dropdown-spacer{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-color: #8a8aa3 !important;
}
.dime-dropdown-item, .dime-dropdown-item-evenrow{
  outline: none !important;
  padding-left: 15px;
  padding-right: 15px;  
  font-size: 0.95rem;
}
.dime-dropdown-lt-item, .dime-dropdown-lt-item-evenrow{
  outline: none !important;
  padding-left: 10px;
  padding-right: 10px;  
  font-size: 0.8rem;
}
.dime-dropdown-item-evenrow, .dime-dropdown-lt-item-evenrow{
  background-color: #eeeefd;
}
.dime-dropdown-item.active, .dime-dropdown-item:active,
.dime-dropdown-item-evenrow.active, .dime-dropdown-item-evenrow:active,
.dime-dropdown-lt-item.active, .dime-dropdown-lt-item:active,
.dime-dropdown-lt-item-evenrow.active, .dime-dropdown-lt-item-evenrow:active {
  background-color: #bbbbd4;
  color: rgb(33, 37, 41);
}
.dime-dropdown-item.hover, .dime-dropdown-item:hover,
.dime-dropdown-item-evenrow.hover, .dime-dropdown-item-evenrow:hover {
  background-color: #8a8aa3;
}
.dime-dropdown-lt-item.hover, .dime-dropdown-lt-item:hover,
.dime-dropdown-lt-item-evenrow.hover, .dime-dropdown-lt-item-evenrow:hover {
  background-color: #60607a;
}

.dime-dropdown-menu{
  outline: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-color: #8a8aa3 !important;
}

.dime-button, .dime-button:focus, .dime-button.focus{
  background-color: #747494 !important;
  border: none !important;
  outline: none !important;
  border-color: rgba(0,0,0,0.0) !important;
  font-size: 1.025rem;
}
.dime-button:hover, .dime-button.hover{
  background-color: #3f3f52;
}

.dime-tab-close, .dime-tab-close:focus, .dime-tab-close.focus{
  /* background-color: rgba(0,0,0,0.0) !important; */
  background-color: #8a8aa3 !important;
  border: none !important;
  outline: none !important;
  border-color: rgba(0,0,0,0.0) !important;
  font-size: 0.85rem;
  /* color: #3f3f52 !important; */
  color: #fff !important;
}
.dime-tab-close:hover, .dime-tab-close.hover{
  /* background-color:#bbbbd4 !important; */
  background-color:#60607a !important;
  /* color: #60607a !important; */
}

.dime-datepicker{
  background-color: #747494 !important;
  border: none !important;
  outline: none !important;
  border-color: rgba(0,0,0,0.0) !important;
  /* height: 36.6px; */
  line-height: 2.285rem;
  font-size: 1.025rem;  
  color: #fff;
  border-radius: 4px;
  /* padding: 10px 5px 5px 10px; */
  padding-left: 11px;
  padding-right: 10px;
  /* width: 75% !important; */
  width: 150px !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;  
}
.dime-datepicker-label{
  color: #fff;
  white-space: nowrap !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;  
}
.dime-datepicker-label-div{
  display: inline-block;
  position: relative;
  top: 0;
  left: 140px;
  /* right: 20px; */
  z-index: 20;
  margin-left: -15px !important;
  padding-left: 0 !important;  
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;  
  
}

/* fix date picker text color */
.dime-datepicker::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}
.dime-datepicker:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}
.dime-datepicker::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}

/* react-datepicker override */
.react-datepicker__day--selected{
  background-color: #8a8aa3 !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;  
}
.react-datepicker__close-icon{
  margin-right: 27px !important;
  padding-right: 0px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;  
}
.react-datepicker__close-icon::after{
  background-color: #efefef !important;
  color: #3f3f52 !important;
  font-size: 0.9rem !important;
  line-height: 0.9rem !important;
  height: 14px !important;
  width: 14px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /* remove the x cirlce */
  border-radius: 2px !important;
  content: "\0061\006c\006c" !important;
}
/* bootstrap override */
.btn, btn:hover, .btn.hover, .btn:focus, .btn.focus,
.bnt-primary, .bnt-primary:hover, .bnt-primary.hover, .bnt-primary:focus, .bnt-primary.focus{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.nav-tabs{
  line-height: 1.2 !important;
  border-color:  #bbbbd4  !important;
}
.nav-link{ 
  /* padding: 0.4rem, 0.95rem !important; */
  padding-top: 0.4rem !important;
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
  padding-bottom: 0.4rem !important;
  color: #60607a !important;
  font-size: 0.95rem !important;
}
.nav-tabs .nav-link{
  border-color:  #bbbbd4  !important;

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
{
  color: #333 !important;
  font-weight: 600 !important;
  /* font-size: 0.95rem !important; */

  background-color: #bbbbd4 !important;
  border-color:  #60607a #60607a #bbbbd4 !important;

  /* padding: 0.4rem, 0.5rem !important; */
}

.nav-tabs .nav-link:hover{ 
  color: #483D8B !important;
  /* border-bottom-color: #bbbbd4 !important; */
}

/*  dime bootstrap-switch-button override */
.dime-switch, .dime-switch-light {
  margin-right: 7px !important;
  border-radius: 5px !important;
  border: 1px solid #495057 !important;
}
.dime-switch .switch-on, .dime-switch-light .switch-on {
  background-color: #747494 !important;
}
/* .dime-switch .switch-off {
  background-color: #6c757d !important;
} */

.dime-switch .switch-on, .dime-switch .switch-off,
.dime-switch-light .switch-on, .dime-switch-light .switch-off{
  font-size: 0.9rem !important;
  line-height: 0.6rem !important;
}
.dime-switch-light .switch-handle {
  /* border-left: 1px solid #60607a !important; */
  border-right: 1px solid #8a8aa3 !important;
}


/* dime map popup */
.dime-map-popup-body{
  font-size: 0.85rem;
  white-space: pre-wrap;
  margin-left: 3px;
  font-weight: 600;
}
.dime-map-popup-mapdiv{
  width: 100% !important;
  height: 350px !important;
  margin-bottom: 5px !important;
  background-color: #f7f7f7;
  border: 1px solid rgba(30, 143, 255, 0.5);  
}

/* dime map popup */
.dime-map-tab-body{
  font-size: 0.85rem;
  white-space: pre-wrap;
  margin-left: 3px;
  font-weight: 600;
}
.dime-map-tab-mapdiv{
  width: 100% !important;
  height: 500px !important;
  margin-bottom: 5px !important;
  margin-right: 25px !important;
  background-color: #f7f7f7;
  border: 1px solid rgba(30, 143, 255, 0.5);  
}