$sidebar-bg-color: #bbbbd4 !default; 
//$sidebar-color: #2c2c3a !default;
$sidebar-color: #3f3f52 !default;
//$sidebar-width: 270px !default;
$sidebar-width: 250px !default;
$sidebar-collapsed-width: 75px !default;
$highlight-color: #fbfbfb !default;
$submenu-bg-color: #8a8aa3 !default;
$submenu-bg-color-collapsed: #8a8aa3 !default;
//$icon-bg-color: #60607a !default;
$icon-bg-color: #747494 !default;
$icon-size: 35px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;


@import '~react-pro-sidebar/dist/scss/styles.scss';

/* other overrides */
.pro-active-bk{
  /* this is the active choosen menu item, not a class part of proslider (made it up) */
  // background-color: #8a8aa3;
  color: $highlight-color;
  text-shadow: 1px 1px rgba(0,0,0, 0.2);
}
.pro-inner-item{
    font-size: 1.025rem !important;
    // font-weight: 600;
}
.pro-inner-item:hover{
  text-shadow: 1px 1px rgba(0,0,0, 0.2);
}
.pro-icon{
    font-size: 1.2rem !important;
}
.pro-item-content, .pro-item-content a{
  outline: none !important;
  border: none !important;
}
.menu-collaspe-icon{
  text-align: right;
  font-size: 1.55rem;
  padding-right: 10px;
  color: #60607a;
}
.menu-collaspe-icon:hover{
  color: $highlight-color;
  cursor: pointer;
}

//// entire menu has side border for crisp look
// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
//     border-right: 1px solid #8a8aa3;
// }
//// remove header border, blends in logo with main menu
// .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header{
//     border-bottom: none  !important;
// }

// rest of this css is pulled from github pro-sidebar site.  https://github.com/azouaoui-med/react-pro-sidebar/tree/master/demo/src
body {
    margin: 0;
    height: 100vh;
    // color: #353535;  /* fjp - 9/16/2020 */
    // font-family: 'Roboto', sans-serif;  /* fjp - 9/16/2020 */
  }
  
  #root {
    height: 100%;
  }

.app {
    height: 100%;
    display: flex;
    position: relative;
  
    .btn-toggle {
      cursor: pointer;
      width: 40px;
      height: 40px;
    //   background: #353535;
    background: #3f3f52;  /* fjp - 9/16/2020 */
      color: #fff;
      text-align: center;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      display: none;
    border: 2px solid #fff;  /* fjp - 9/16/2020 - shimming to get clickable area bigger */
    }

    
  
    .sidebar-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .sidebar-btn {
        padding: 1px 15px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.05);
        color: #adadad;
        text-decoration: none;
        margin: 0 auto;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        span {
          margin-left: 5px;
          font-size: 13px;
        }
        &:hover {
          color: $highlight-color;
          /* added for DIME - fjp */
          text-shadow: 1px 1px rgba(0,0,0, 0.2) !important;
        }
      }
    }
  
    .collapsed {
      .sidebar-btn {
        display: inline-block;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        line-height: 40px;
        padding: 0;
      }
    }
  
    main {
    //   padding: 24px;   /* fjp - 9/16/2020 */
      padding: 0px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      header {
        // margin-bottom: 40px;
        margin-bottom: 10px;
        border-bottom: 1px solid #efefef;
        h1 {
          display: flex;
          align-items: center;
          transform: translateX(-20px);
        }
      }
  
      footer {
        margin-top: auto;
        // color: #888;
        color: #333;   /* fjp - 9/16/2020 */
        text-align: center;
        padding-bottom: 5px;
        a {
          text-decoration: none;
        //   color: #888;
          color: #333;   /* fjp - 9/16/2020 */
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .social-bagdes {
      margin-top: 10px;
      img {
        margin: 5px;
      }
    }
  
    .block {
      display: flex;
      margin-bottom: 24px;
      font-size: 14px;
      color: #545454;
      > span {
        margin-left: 10px;
      }
    }
  
    &.rtl {
      direction: rtl;
      text-align: right;
  
      header {
        h1 {
          transform: translateX(20px);
        }
      }
      .block {
        direction: rtl;
        > span {
          margin-left: 0;
          margin-right: 10px;
        }
      }
      .sidebar-btn-wrapper {
        .sidebar-btn {
          span {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }
    }
  
    @media (max-width: 767.98px) {
      overflow-x: hidden;
      header {
        h1 {
          font-size: 24px;
        }
      }
    }
    @media (max-width: $breakpoint-md) {
      .btn-toggle {
        display: flex;
      }
  
      &.rtl {
        .btn-toggle {
          margin-left: auto;
        }
      }
    }
  }
  
  .badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
    &.red {
      color: #ffffff;
      background: #d63030;
    }
    &.gray {
      color: #ffffff;
      background: #777777;
    }
    &.yellow {
      color: #000000;
      background: #ffd331;
    }
  }